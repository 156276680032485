import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import { FilledButton } from '@jsluna/button';
import { Display2 } from '@jsluna/typography';
import { TextInputField } from '@jsluna/form';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Guideline = makeShortcode("Guideline");
const Do = makeShortcode("Do");
const Dont = makeShortcode("Dont");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#design-with-screen-readers-in-mind"
            }}>{`Design with screen readers in mind`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#dont-make-things-sound-easy"
            }}>{`Don't make things sound easy`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#use-inclusive-language"
            }}>{`Use inclusive language`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "design-with-screen-readers-in-mind",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#design-with-screen-readers-in-mind",
        "aria-label": "design with screen readers in mind permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Design with screen readers in mind`}</h2>
    <p>{`A screen reader allows people who are blind or visually impaired to use our digital products. Screen readers can read out the words on the screen and navigate content in multiple ways.`}</p>
    <p>{`While sighted users can take in many visual cues when scanning a page, screen readers can only tell people one piece of information at a time. So when designing content, think about how it would be read aloud by a screen reader. `}</p>
    <p>{`All of the content needed to complete an action should be clearly labelled for screen reader users. `}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <div>When there is no visible text on screen, provide a descriptive text alternative for all buttons, links and controls to convey meaning for screen reader users.</div>
    <div>Give descriptive alt text for images and don't use images of text.</div>
  </Do>
  <Dont mdxType="Dont">
  <div>Don't refer to where things physically are on the screen.</div>
  <div>Don't use generic link text like 'click here'.</div>
  </Dont>
    </Guideline>
    <hr></hr>
    <h2 {...{
      "id": "dont-make-things-sound-easy",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dont-make-things-sound-easy",
        "aria-label": "dont make things sound easy permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Don't make things sound easy`}</h2>
    <p>{`It's important that we don't assume things will be easy for people, as this won't be the case for everyone. `}</p>
    <p>{`So avoid words like "easy", "quick" or "simple" in content. Instead try to be specific about how an action is completed or the benefits of completing the action. `}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <div>Send an invoice in 3 steps</div>
    <div>Create an account for a personalised experience</div>
    <div>Collect your order at the collection point</div>
  </Do>
  <Dont mdxType="Dont">
    <div>Send an invoice in 3 simple steps</div>
    <div>Creating an account is quick and easy</div>
    <div>Collecting your order is a piece of cake</div>
  </Dont>
    </Guideline>
    <p>{`We should also avoid words like "just", "simply" or "only". We use these words a lot when we're trying to minimise the effort or impact of our content and make things sound easy. `}</p>
    <p>{`So we might say "just send us an email" in our content. But for a variety of reasons, sending an email is not something that a lot of people can 'just' do. `}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">
    <div>Give us your account details</div>
    <div>Your delivery cost is £4.99</div>
    <div>Call our Help Centre</div>
  </Do>
  <Dont mdxType="Dont">
    <div>Simply give us your account details</div>
    <div>Your delivery cost is only £4.99</div>
    <div>Just pick up the phone and contact our Help Centre</div>
  </Dont>
    </Guideline>
    <p>{`When setting expectations in content, avoid saying how long something should take to complete, as again this won't be the same for everyone. `}</p>
    <Guideline mdxType="Guideline">
  <Do mdxType="Do">Our feedback survey has 10 multiple choice questions</Do>
  <Dont mdxType="Dont">Our feedback survey will take just 2 minutes to complete</Dont>
    </Guideline> 
    <hr></hr>
    <h2 {...{
      "id": "use-inclusive-language",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#use-inclusive-language",
        "aria-label": "use inclusive language permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Use inclusive language`}</h2>
    <p>{`Using inclusive language means choosing words that are respectful and considerate. So always use gender neutral terms over gendered ones.`}</p>
    <Guideline mdxType="Guideline">
  <Do title="Gender neutral terms" mdxType="Do">
    <div>you</div>
    <div>they</div>
    <div>spokesperson</div>
    <div>businessperson</div>
  </Do>
    </Guideline>
    <p>{`You should avoid referencing gender, age, disability, ethnicity or religion unless it's absolutely relevant to the content you're producing. `}</p>
    <p>{`If you do need to talk about any of these things, it's important to remember that people don't always agree on what are the most inclusive terms and they can change over time.`}</p>
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We can help answer any questions around content or get involved with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      